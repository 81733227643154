<template>
  <body id="poster">
    <div
      style="   width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;"
    >
      <el-form class="login-container" label-position="left" label-width="0px">
        <h3 class="login_title">系统登录</h3>
        <el-form-item>
          <el-input
            type="text"
            v-model="loginForm.username"
            auto-complete="off"
            placeholder="账号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            type="password"
            v-model="loginForm.password"
            auto-complete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>

        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%;background: #505458;border: none"
            v-on:click="doLogin"
          >
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </body>
</template>

<script>
import { login } from '../../api/user.js'

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: process.env.VUE_APP_USER_NAME,
        password: process.env.VUE_APP_PASSWORD
      },
      responseResult: []
    }
  },
  methods: {
    async doLogin() {
      // let that = this
      const userInfo = await login(this.loginForm)
      // const userInfo = await authLogin({ origin, auth })
      // await loginUserInfo().then(resp => {
      //   window.localStorage.setItem('roles', resp.roles)
      //   sessionStorage.setItem('userInfo', JSON.stringify(resp))
      //   that.$store.commit('user/SET_INFO')
      // })
      if (userInfo.is_staff) {
        window.localStorage.setItem('roles', 'ADMIN|USER')
      } else {
        window.localStorage.setItem('roles', 'USER')
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$store.commit('user/SET_INFO')
      window.localStorage.setItem('origin', '/login')
      // loadingInstance.close()
      this.$router.push('/')
      // await loginUserInfo().then(resp => {
      //   console.log(resp)
      //   //console.log(resp.roles)
      //   window.localStorage.setItem('roles', resp.roles)
      //   sessionStorage.setItem('userInfo', JSON.stringify(resp))
      //   that.$store.commit('user/SET_INFO')
      // })
      this.$router.push('/')
    }
  }
}
</script>

<style>
#poster {
  background: url('../../assets/loginImages/background.jpg') no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}

body {
  margin: 0px;
  padding: 0;
}

.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
</style>
